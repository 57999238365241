import React, { Component } from 'react';
import '../css/unidades.css';
import ImageGallery from "react-image-gallery";

class UnidadesNegocio extends Component {
    constructor(props) {
        super(props);
        this.params = new URLSearchParams(window.location.search);
        this.state = {
            unidadSeleccionada: 'obraCivilEspecializada',
        }
    }

    componentDidMount = () => {
        if (this.params.get('servicio')) {
            document.getElementById('unidades').scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
            this.setState({
                unidadSeleccionada: this.params.get('servicio')
            })            
        }
    }

    imagesObraCivilEspecializada = [
        {
          original: "/img/obraCivil0.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil1.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil2.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil3.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil4.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil5.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil6.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil7.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil8.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil9.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil10.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
        {
          original: "/img/obraCivil11.jpg",
          originalWidth: '100',
          originalHeight: '400px'
        },
      ];
    
    imagesInfraestructura = [
        {
            original: "/img/infraestructura0.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura1.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura2.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura3.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura4.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura5.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/infraestructura6.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
      ];
    
    imagesEdificacion = [
        {
            original: "/img/edificacion0.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion1.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion2.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion3.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion4.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion5.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion6.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion7.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion8.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
        {
            original: "/img/edificacion9.jpg",
            originalWidth: '100',
            originalHeight: '400px'
        },
      ];
    
    render() { 
        return ( 
            <>
            <div className='row m-0' id='unidades' name="unidades">
                <div className="unidades d-flex justify-content-center mx-auto">
                    <div className="title1 col-4">¿Que ofrecemos?</div>
                    <div id='unidadObraCivil' className={"title " + (this.state.unidadSeleccionada === 'obraCivilEspecializada' ? 'unidadSeleccionada' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ unidadSeleccionada: 'obraCivilEspecializada' })}>Obra civil especializada</div>
                    <div id='unidadInfraestructura' className={"title " + (this.state.unidadSeleccionada === 'infraestructura' ? 'unidadSeleccionada' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ unidadSeleccionada: 'infraestructura' })}>Infraestructura</div>
                    <div id='unidadEdificacion' className={"title " + (this.state.unidadSeleccionada === 'edificacion' ? 'unidadSeleccionada' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ unidadSeleccionada: 'edificacion' })}>Edificación</div>
                    <div className="title titleGarteel" style={{ border: '3px solid #2EBF70', borderRadius: '5px', marginInlineStart: '150px' }}><a href='https://www.garteel.com/' rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: 'inherit', width: '100%', height: '100%' }}><span style={{ fontSize: '10px' }}>Para proyectos residenciales, visita: </span><br />Garteel</a></div>
                </div>
            </div>
            {
                this.state.unidadSeleccionada === 'obraCivilEspecializada' &&
                <div className="row m-0 mt-5 obraCivilEspecializada animate__animated animate__pulse">
                    <div className='py-4 mx-auto' style={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', width: '1300px' }}>
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center text-start">
                            <div>
                                <h1 className='text-center' style={{ fontSize: '28px' }}>Obra civil especializada</h1>
                                <hr />
                                <ul>
                                    <li className='mb-3 mb-lg-4 liServicios'>Construcción de estructuras metálicas diseñadas para espacios de oficinas, bodegas, naves industriales y cubiertas de gran formato.</li>
                                    <li className='mb-3 mb-lg-4 liServicios'>Cimentaciones profundas, fosas para tanques de combustibles y plantas de tratamiento de agua residual.</li>
                                    <li className='mb-3 mb-lg-4 liServicios'>Construcción de terracerías y pavimentos.</li>
                                    <li className='liServicios'>Estudios y proyectos de ingeniería aplicados a la construcción.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div style={{ height: '400px' }}>
                                <ImageGallery items={this.imagesObraCivilEspecializada} showThumbnails={false} showBullets={false} showNav={false} showPlayButton={false} showFullscreenButton={false} autoPlay/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }
            {
                this.state.unidadSeleccionada === 'infraestructura' &&
                <div className="row m-0 mt-5 infraestructura animate__animated animate__pulse">
                    <div className='py-4 mx-auto' style={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', width: '1300px' }}>
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center text-start">
                            <div>
                                <h1 className='text-center' style={{ fontSize: '28px' }}>Infraestructura</h1>
                                <hr />
                                <ul>
                                    <li className='mb-3 mb-lg-4 liServicios'>Sistemas de generación de energía a través de paneles solares.</li>
                                    <li className='mb-3 mb-lg-4 liServicios'>Lineas de conducción y distrubución de agua potable.</li>
                                    <li className='mb-3 mb-lg-4 liServicios'>Sistemas de drenaje sanitario, redes de atarjeas y colectores pluviales.</li>
                                    <li className='liServicios'>Sistemas de alimentación eléctrica en baja y media tensión.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div style={{ height: '400px' }}>
                                <ImageGallery items={this.imagesInfraestructura} showThumbnails={false} showBullets={false} showNav={false} showPlayButton={false} showFullscreenButton={false} autoPlay/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }
            {
                this.state.unidadSeleccionada === 'edificacion' &&
                <div className="row m-0 mt-5 edificación animate__animated animate__pulse">
                    <div className='py-4 mx-auto' style={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', width: '1300px' }}>
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center text-start">
                            <div>
                                <h1 className='text-center' style={{ fontSize: '28px' }}>Edificación</h1>
                                <hr />
                                <ul>
                                    <li className='mb-3 mb-lg-4 liServicios'>Sector educativo: iniciativa privada e institucional.</li>
                                    <li className='liServicios'>Sector industrial y de servicios, tales como: oficinas, puntos de venta y comedores.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div style={{ height: '400px' }}>
                                <ImageGallery items={this.imagesEdificacion} showThumbnails={false} showBullets={false} showNav={false} showPlayButton={false} showFullscreenButton={false} autoPlay/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }
            </>
         );
    }
}
 
export default UnidadesNegocio;