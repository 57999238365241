import React from 'react';
import '../../css/elegir.css'
import img1 from '../../img/img_conf.png'
import img2 from '../../img/img_segur.png'
import img3 from '../../img/img4_wr.png'
import img4 from '../../img/Mapa.png'


const PorqueElegirnos = () => {
  return (
    <section id="why-choose-us" className="">
      <div className="row m-0">
        <div className="col-lg-5 text-pres d-flex align-items-center">
          <h5 style={{ fontSize: '26px' }}>Nuestro trabajo se ha destacado en los siguientes estados: </h5>
        </div>
        <div className="col-lg-7">
          <img src={img4} style={{ width: '100%' }} alt="img4" className="img-card mx-auto" />
        </div>
      </div>
      <div className="container-elegir mx-auto justify-content-center px-4 mb-3">
        <div className="row">
          <div className="col-lg-12 text-center mt-4 mb-2">
            <p className="lead">Construimos siempre pensando en:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pb-4">
            <div className="card mb-4 mx-auto justify-content-center h-100">
              <img src={img1} style={{ width: '80px' }} alt="Confiabilidad" className="img-card mx-auto" />
              <div className="card-body">
                <h5 className="card-title mx-auto text-center">Nuestro principal objetivo: </h5>
                <h5 className="card-title mx-auto text-center">El bienestar de la sociedad.</h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 pb-4">
            <div className="card mb-4 mx-auto justify-content-center h-100">
              <img src={img2} style={{ width: '80px' }} alt="Experiencia" className="img-card mx-auto" />
              <div className="card-body">
                <h5 className="card-title mx-auto text-center">Nuestra misión:</h5>
                <h5 className="card-title mx-auto text-center">Diseñar obras que hagan de México un lugar mejor.</h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 pb-4">
            <div className="card mb-4 mx-auto justify-content-center h-100">
              <img src={img3} style={{ width: '80px' }} alt="Seguridad" className="img-card mx-auto" />
              <div className="card-body">
                <h5 className="card-title mx-auto text-center">Nuestra visión:</h5>
                <h5 className="card-title mx-auto text-center">Un planeta sostenible y desarrollado.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PorqueElegirnos;
