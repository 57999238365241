import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from './components/AppRoutes';
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import WhatsappBtn from './components/WhatsappBtn';

function App() {
  return (
    <Router>
        <NavBar/>
        <AppRoutes/>
        <Footer/>
        <WhatsappBtn/>
    </Router>
  );
}

export default App;
