import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import '../css/footer.css'

class Footer extends React.Component {
    render() {


        return (
            <>
                <footer className="footer">
                    <div className="cont-footer px-lg-4">
                        <div className="row mx-auto px-lg-4">
                            <div className="col-12 col-lg-6 text-center">
                                <img src="/logoBlanco.svg" alt="" width="120px" className='mb-3'/>
                                <p className='text-center'>"Especializados en construir obras para el desarrollo y bienestar de nuestra sociedad. Creemos en México."</p>
                            </div>
                            <div className="cont-list2 col-12 col-lg-6">
                                <h5>Contacto</h5>
                                <ul className="list-unstyled">
                                    <li><FontAwesomeIcon icon={faPhoneAlt} className="mx-auto"/> 7714733271 </li>
                                    <li><FontAwesomeIcon icon={faEnvelope} className="mx-0"/> contacto@grupoagcinfraestructura.com</li>
                                    <li><FontAwesomeIcon icon={faMapMarkerAlt} className="mx-1"/>Pachuca de Soto, Hidalgo</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </>

        );
    }
}

export default Footer;
