import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './Home';
import Contacto from './C/ContactForm';
import { AnimatePresence } from 'framer-motion';

function AppRoutes() {

  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Home />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
    </AnimatePresence>
  )

}

export default AppRoutes;
