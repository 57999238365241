import React from 'react';
import { Navbar, Container, Nav, Button, Offcanvas } from 'react-bootstrap';
import logoBlanco from '../img/logoBlanco.svg';
import '../css/navbar.css';

class NavBar extends React.Component {

    constructor(){
        super();
        this.state = {
            show : false,
        }
    }

    componentDidMount = () => {
        
    }

    handleShow = () =>{
        this.setState({
            show: true
        })
    }

    handleClose = () =>{
        this.setState({
            show:false
        })
    }

    prueba = () => {
        console.log("escondido")
    }

    focus = async (e, unidad) => {
        if (window.location.pathname === '/') {
            e.preventDefault();         
            document.getElementById(unidad).click();
            document.getElementById('unidades').scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
            this.setState({
                show: false
            })
        }else{

        }
    }

    render() { 
        return (
            <>
                <Navbar expand="sm" expanded={false} className={window.location.pathname ===  '/contacto' ? 'bg-dark' : ''}>
                    <Container fluid>
                        <Navbar.Brand href="/">
                        <img
                            src={logoBlanco}
                            height="60"
                            className="d-inline-block align-top animate__animated animate__fadeInLeft"
                            alt="Logo Garteel"
                        />
                        </Navbar.Brand>
                        <Navbar.Collapse>
                                <Nav className='ms-auto'>
                                    <Nav.Link className={window.location.pathname ===  '/contacto' ? 'activo' : ''} href="/contacto">Contacto</Nav.Link>
                                </Nav>
                        </Navbar.Collapse>
                        <Button variant="" onClick={this.handleShow} className="me-2 border-0 mt-0 ms-0">
                            <i style={{ fontSize: '30px', color: 'white' }} className="bi bi-list"></i>
                        </Button>
                        <Offcanvas show={this.state.show} onHide={this.handleClose} placement="end" id="offcanvasExample" restoreFocus={false}>
                            <Offcanvas.Header closeButton closeVariant='white' style={{ border: 'none' }}>
                                <a href="/" style={{ width: '40%', marginLeft: '30%', marginRight: 'auto' }}>
                                    <img
                                        src={logoBlanco}
                                        height="40"
                                        className="logoBlancoMenu animate__animated animate__fadeIn"
                                        alt="Logo Blanco"
                                    />
                                </a>
                            </Offcanvas.Header>
                            <Offcanvas.Body className='pt-0'>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown ' + (window.location.pathname === '/contacto' ? 'activo' : '')} style={{ animationDelay: '0s' }} href="/contacto">Contacto</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown '} style={{ animationDelay: '0.1s' }} href="/?servicio=obraCivilEspecializada" name="unidades" onClick={(e) => this.focus(e, 'unidadObraCivil')}>Obra especializada</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown '} style={{ animationDelay: '0.2s' }} href="/?servicio=infraestructura" name="unidades" onClick={(e) => this.focus(e, 'unidadInfraestructura')}>Infraestructura</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown '} style={{ animationDelay: '0.3s' }} href="/?servicio=edificacion" name="unidades" onClick={(e) => this.focus(e, 'unidadEdificacion')}>Edificación</Nav.Link>
                                <Nav.Link className={'my-3 linkOffCanvas animate__animated animate__fadeInDown '} style={{ animationDelay: '0.3s' }} href="https://www.garteel.com/">Garteel</Nav.Link>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
            </>
        );
    }
}
 
export default NavBar;