import React from 'react';
import '../css/syles.css'
import { useSpring, animated } from 'react-spring';
import Elegir from './C/PorqueElegirnos'
import ImagenEnd from '../components/C/Imagen'
import { motion } from "framer-motion";
import UnidadesNegocio from './UnidadesNegocio';
import 'animate.css';


function Home() {

  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(100%)' },
    to: { opacity: 1, transform: 'translateY(0%)' },
    delay: 500,
  });

  return (
    <motion.div className="container-fluid inicio">
      <div className="principal">
          <div className="contImg1">
              <div className='overlay' />
              <img src="/img/port1.jpg" alt="" id='img1' className='img1' width="100" height="100" />
          </div>
          <div className="contenedoPrincipal justify-content-center text-center align-items-center">
              <div className='row contenedorServicios mt-5'>
                  <div className="elementoServicio text-white">
                      <h1 className='text-center'>Grupo AGC</h1>
                      <h2 className='subtitle'>Infraestructura y pavimentos</h2>
                      <hr />
                      <h2 className='subtitle'>"Especializados en construir obras para el desarrollo y bienestar de nuestra sociedad. Creemos en México."</h2>
                  </div>
              
              </div>
          </div>
      </div>
      <div className='home-1'>
        <div className="about">
          <div className='row m-0 d-flex justify-content-center pt-5'>
            <div><animated.h2 style={props}>¿Quienes somos?</animated.h2>
              <div className='linear justify-content-center mx-auto mb-3'><hr /></div></div>
            <div className='col-sm-12'>
              <div className='p-2 px-5 text-center'>
                <p>Grupo AGC es una empresa constructora creada y dirigida por empresarios mexicanos quienes tienen la convicción de hacer de México un lugar mejor, más conectado, más sostenible, con desarrollo económico y siempre buscando el bienestar de nuestra sociedad.</p>
                <p>Diseñamos, planificamos y construimos enfocándonos en hacer proyectos funcionales y de excelencia. Contáctanos.</p>
              </div>
            </div>
          </div>
          <UnidadesNegocio/>
        </div>
      </div>
      <Elegir />
     <div className='m-0 p-0'><ImagenEnd /></div> 
    </motion.div>
  );
}

export default Home;
