import React from 'react';
import {  useSpring, animated } from 'react-spring';
import '../../css/imagen.css'


function Imagen() {

  const fadeIn = useSpring({
    from: { opacity: 0, transform: 'translateX(-50px)' },
    to: { opacity: 1, transform: 'translateX(0px)' },
    config: { duration: 600 },
  });

  return (
    <div className="container-fluid m-0 p-0">
      <div className='end-container'>
        <div className="content col-lg-6 text-center mx-auto ">
          <animated.h1 style={fadeIn} className="mt-5">+ 100 proyectos consolidados</animated.h1>
         <a href="/contacto" style={{textDecoration: 'none'}}> <button className='btnContacto mt-4'><span>Contacto</span></button></a>
          
        </div> 
      </div>
    </div>
  );
}

export default Imagen;
